<template>
  <div class="">
    <span class="text-h4">I DRAW:</span> <br>• Brawlhalla style <br>• Anime style <br>• Realistic <br>• Humanoid <br>• Furry

    <br><br>
    <span class="text-h4">I DON'T DRAW:</span> <br>• Gore <br>• NSFW<br> • Offensive content, such as homophobic, xenophobic, or any other incitement of hatred. 
  </div>
</template>

<script>
export default {
  name: 'WhatDoIDraw-Content'
}
</script>

<style scoped>

</style>
