<template>
  <v-app>
    <v-app-bar
      app
      inverted-scroll
      color="white"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Carol Logo"
          class="shrink mr-8 mt-1"
          contain
          src="./assets/images/header/signature.webp"
          transition="scale-transition"
          width="150px"
          @click="$vuetify.goTo(0)"
        />
      </div>

      <v-spacer></v-spacer>

      <div
        v-if="!this.$vuetify.breakpoint.mobile"
      >
        <v-btn
          :key="index" 
          v-for="(item, index) in icons"
          class="mx-5"
          fab
          icon
          :href="item.to"
          target="_blank"
        >
          <v-icon> {{ item.icon }} </v-icon>
        </v-btn>
      </div>

      <v-btn
        text
        @click="$vuetify.goTo('#Contact')"
      >
        <span class="mr-2">Contact me</span>
        <v-icon>{{ icon.mdiEmail }}</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <Header/>
      <Portfolio/>
      <contentBase
        title="What Do I Draw"
        src="https://cdna.artstation.com/p/assets/images/images/024/317/526/small/carol-galvao-juskit-juskit-carol-galvao-6-sem-titulo.jpg"
      >
        <WhatDoIDraw />
      </contentBase>

      <contentBase
        id="Contact"
        title="Want Your Idea Illustrated?"
        src="https://cdnb.artstation.com/p/assets/images/images/024/318/473/small/carol-galvao-juskit-corsair-orion.jpg"
        rtl
        dark
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="10"
            lg="8"
          >
            <v-form
              ref="form"
              class=""
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="contactForm.name"
                :rules="[rules.required]"
                label="Your Name Here Or @"
                required
                filled
                shaped
              ></v-text-field>

              <v-text-field
                v-model="contactForm.email"
                :rules="[rules.email, rules.required]"
                label="Your Best E-mail"
                required
                filled
              ></v-text-field>
              
              <v-select
                v-model="contactForm.item"
                :append-icon="icon.mdiChevronDown"
                :items="items"
                :rules="[rules.required]"
                label="What Fits Best Your Desires"
                required
                filled
              >
              </v-select>

              <v-textarea
                v-model="contactForm.message"
                label="Please Write A Brief Description Of The Illustration You Are Looking For"
                required
                filled
              ></v-textarea>

              <v-text-field
                :key="index"
                v-for="(item, index) in contactForm.references"
                v-model="item.url"
                label="Reference URL"
                required
                filled
              ></v-text-field>

              <v-btn
                v-if="contactForm.references.length < 10"
                :disabled="!valid"
                color="primary"
                class="mr-4"
                @click="addReference"
              >
                Add Reference URL
              </v-btn>

              <br>
              <v-btn
                :disabled="!valid"
                color="primary"
                class="mr-4 mt-7"
                @click="send()"
                :loading="loading"
              >
                Send
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </contentBase>

      <footerModule />

      <v-snackbar
        v-model="snackbar.state"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import Header from './components/Header'
import Portfolio from './components/Portfolio'
import contentBase from './components/contentBase'
import footerModule from './components/footer'
import WhatDoIDraw from '../easyEdit/whatDoIDraw'

import refIcons from '../easyEdit/refIcons'
import ruleSet from './assets/ruleSet.js'
import { mapActions } from 'vuex'

import {mdiEmail, mdiChevronDown} from '@mdi/js'

export default {
  name: 'App',

  components: {
    Header,
    Portfolio,
    contentBase,
    footerModule,
    WhatDoIDraw,
  },

  data: () => ({
    icons: refIcons,
    icon: {
      mdiEmail,
      mdiChevronDown
    },
    valid: true,
    items: [
      'Full Body',
      'Head',
      'Banner',
      'Logo',
    ],
    contactForm: {
      name: '',
      email: '',
      message: '',
      item: '',
      references: []
    },
    rules: ruleSet,
    snackbar: {
      state: false,
      color: 'error',
      message: 'Network error. Please try again.',
      timeout: 4000,
    },
    loading: false
  }),

  methods: {
    ...mapActions('contactModule', ['sendEmail']),
    send () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.sendEmail(this.contactForm)
          .then((res) => {
            Object.assign(this.snackbar, res)
            this.loading = false
          })
      }
    },
    addReference () {
      this.contactForm.references.push({url: ''})
    }
  },
}
</script>
