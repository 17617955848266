/* eslint-disable no-useless-escape  */
const ruleSet = {
  required: (value) => {
    if (!value) {
      return 'Campo obrigatório.'
    } else { return true }
  },
  nFicha: (value) => {
    if (!value) { return true } else { return true }
  },
  data: (value) => {
    if (!value) { return true }
    const dataClear = value.match(/[0-9]+/g)
    if (dataClear.join('').length < 8) {
      return 'Data inválida'
    } else if (dataClear[0] > 31 || dataClear[0] < 1) {
      return 'Dia inválido'
    } else if (dataClear[1] > 12 || dataClear[1] < 1) {
      return 'Mês inválido'
    } else { return true }
  },
  futureDate: (value) => {
    const dataClear = value.match(/[0-9]+/g)
    if (!dataClear) { return true }
    const date = new Date(dataClear[2] * 1, dataClear[1] * 1 - 1, dataClear[0] * 1, 0, 0, 0)
    if ((Date.now() - date) > 0) {
      return 'Data inválida'
    } else {
      return true
    }
  },
  cep: (value) => {
    if (!value) { return true }
    return value.match(/([0-9]+)/g).join('').length !== 8 ? 'CEP inválido.' : true
  },
  cpf: (value) => {
    if (!value) { return true }
    value = '' + value
    const cpfClear = value.match(/[0-9]+/g).join('')
    if (!cpfClear) { return 'CPF inválido' }
    if (cpfClear.length < 11) { return 'CPF inválido' }

    // Função retirada do site da receita federal
    function TestaCPF (strCPF) {
      var Soma
      var Resto
      Soma = 0
      if (strCPF === '00000000000') return false

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      Resto = (Soma * 10) % 11

      if ((Resto === 10) || (Resto === 11)) Resto = 0
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      Resto = (Soma * 10) % 11
      if ((Resto === 10) || (Resto === 11)) Resto = 0
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false
      return true
    }
    if (!TestaCPF(cpfClear)) { return 'CPF inválido' }
    return true
  },
  email: (value) => {
    if (!value) { return true }
    return (!!value.match(/^(([^<>()[\]\\.,;:\s\/\n?@|\"']+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) || 'Email inválido'
  },
  onlyNumbers: (value) => {
    if (!value) { return true }
    return (!!value.match(/[0-9]+/)) || 'Apenas números'
  }
}

export default ruleSet
