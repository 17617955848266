<template>
  <v-card
    :class="dark ? 'black' : ''"
    :dark="dark"
    tile
  >
    <v-row
      class="ma-0"
    >
      <v-col
        v-if="!this.$vuetify.breakpoint.mobile"
        class="pa-0"
        cols="4"
        :order="rtl ? 1 : 0"
      >
        <v-img
          :src="src"
          height="100%"
          min-height="92vh"
        >
        </v-img>
      </v-col>
      <v-col
        class="pa-0"
        :order="rtl ? 0 : 1"
      >
        <v-img
          :src="this.$vuetify.breakpoint.mobile ? src : ''"
          :gradient="this.$vuetify.breakpoint.mobile ? 'to bottom, rgba(200, 200, 200, .1), rgba(0, 0, 0, .99)' : ''"
          width="100%"
          height="100%"
          min-height="92vh"
          :dark="this.$vuetify.breakpoint.mobile"
        >
          <v-col
            class="text-center pa-9"
          >
            <div
              class="text-h2"
            >
              {{ title }}
            </div>
            <br>
            <div
              class="text-h5 display mt-3"
            >
              <slot/>
            </div>
          </v-col>
        </v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'contentBase',
  props: {
    title: {
      type: String
    },
    src: {
      type: String
    },
    dark: {
      type: Boolean
    },
    rtl: {
      type: Boolean
    }
  }
}
</script>