<template>
  <v-row
    v-if="list"
    class="pa-1 ma-0"
    justify="center"
  >
    <swiper
      ref="mySwiper" :options="swiperOptions"
    >
      <swiper-slide :key="index" v-for="(item, index) in list">
        <v-col>
          <PostBase 
            :src="item.src"
            :title="item.title"
            :type="item.type"
            :description="item.description"
          />
        </v-col>
      </swiper-slide>
    </swiper>
  </v-row>
</template>

<script>
import PostBase from './postBase'
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

export default {
  name: 'CarouselModule',
  components: {
    PostBase,
  },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: this.nSlidesPerView(),
        grabCursor: true,
        spaceBetween: 20,
        pagination: {
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
  },
  mounted() {
    // this.swiper.slideTo(1, 1000, false)
    // this.swiper.autoplay.start()
  },
  methods: {
    nSlidesPerView () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 1
        case 'sm': return 1
        case 'md': return 2
        case 'lg': return 3
        case 'xl': return 4
      }
    },
  }
}
</script>