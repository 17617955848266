<template>
  <v-row
    class="ma-0 px-4"
  >
    <span>
      &copy; {{date.getFullYear()}} All Rights Reserved. Carolina S. Galvão
    </span>
    <v-spacer />
    <span>
      Website Made With &hearts; By 
      <a 
        href="https://gugabit.com"
        target="_blank"
        class="gugabit"
      >
        Gugabit
      </a>
    </span>
  </v-row>
</template>

<script>
export default {
  name: 'footerComponent',
  data: () => ({
    date: new Date()
  })
}
</script>

<style>
  .gugabit {
    color: green !important;
    text-decoration: none;
  }

  .gugabit:link {
    color: green;
  }

  /* visited link */
  .gugabit:visited {
    color: green;
  }

  /* mouse over link */
  .gugabit:hover {
    color: green;
  }

  /* selected link */
  .gugabit:active {
    color: green;
  }
</style>