export default [
  {
    title: 'Digital Art',
    images: [
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/031/277/052/small/carol-galvao-carol_juskit-ronin-koji-nsig-bow.jpg?',
        title: 'Ronin Koji',
        type: 'Commissioned Fanart',
        description: 'By: Megavolx E-sports'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/024/318/473/small/carol-galvao-juskit-corsair-orion.jpg',
        title: 'Corsair Orion',
        type: 'Commissioned Fanart',
        description: 'For: a friend'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/018/511/687/small/juskit-carol-galvao-boy.jpg',
        title: 'Pastel',
        type: 'Original',
        description: 'Pastel color training'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/024/087/077/small/carol-galvao-juskit-profile-picture-w-signature.jpg',
        title: 'Jotun Ulgrim and Fenrir Mordex',
        type: 'Commissioned Fanart',
        description: 'For: Xenorakk'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/024/088/655/small/carol-galvao-juskit-nofacegirl.jpg',
        title: 'NO FACE GIRL',
        type: 'Original',
        description: ''
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/024/277/844/small/carol-galvao-juskit-big-rig-bodvar.jpg?',
        title: 'Big Rig Bödvar',
        type: 'Fanart',
        description: 'Re-draw'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/024/316/927/small/carol-galvao-juskit-orio-oia-oia.jpg',
        title: 'Orion Metadev',
        type: 'Fanart',
        description: ''
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/024/317/232/small/carol-galvao-juskit-kor-kawaiinn.jpg',
        title: 'Kor kawai',
        type: 'Fanart',
        description: '4k'
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/024/317/380/small/carol-galvao-juskit-deadly-cute.jpg',
        title: 'First Day Asuri',
        type: 'Fanart',
        description: 'Cute'
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/024/317/526/small/carol-galvao-juskit-juskit-carol-galvao-6-sem-titulo.jpg',
        title: 'Lynx Asuri',
        type: 'Fanart',
        description: 'Requested by: @4asuri'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/024/317/629/small/carol-galvao-juskit-juskit-carol-galvao-9-sem-titulo.jpg',
        title: 'Angry Lil Yumiko',
        type: 'Fanart',
        description: 'Requested by: @dankuigi'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/024/317/929/small/carol-galvao-juskit-lua-atualizado.jpg',
        title: 'Orion and Artemis Fight',
        type: 'Fanart',
        description: ''
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/024/318/028/small/carol-galvao-juskit-petra-reanimated.jpg',
        title: 'Petra Reanimated',
        type: 'Fanart',
        description: 'Halloween ~2019'
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/024/318/302/small/carol-galvao-juskit-fenrir-fire-final.jpg',
        title: 'Fenrir Mordex',
        type: 'Fanart',
        description: 'Requested by: that._happy_.guy'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/024/642/059/small/carol-galvao-juskit-steamsmith-scarlet-final.jpg',
        title: 'Steamsmith Scarlet with Butterscotch',
        type: 'Commissioned Fanart',
        description: 'For: Wanana'
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/024/642/168/small/carol-galvao-juskit-ember-nightshade-protected.jpg',
        title: 'Ember Nightshade',
        type: 'Commissioned Fanart',
        description: 'For: shamefu1'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/026/437/927/small/carol-galvao-juskit-mx-scientist-scarlet-with-glasses.jpg',
        title: 'Mad Scientist Scarlet',
        type: 'Commissoned Fanart',
        description: 'For: @JustSlumpz'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/028/881/903/small/carol-galvao-carol_juskit-gruagach-teros-watermark.jpg',
        title: 'Gruagach Teros',
        type: 'Commissioned Fanart',
        description: ''
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/028/881/807/small/carol-galvao-carol_juskit-petra-terminus.jpg',
        title: 'Terminus Petra',
        type: 'Fanart',
        description: 'Requested by: @gubbyfall to @Buchticky'
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/028/883/182/small/carol-galvao-carol_juskit-bailarinas-final.jpg',
        title: 'Ballerinas',
        type: 'Original',
        description: '2019 Christmas Gift for @wtfboo_ #ASI2K19 @Artenatal'
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/030/133/126/small/carol-galvao-carol_juskit-kitty-azoth.jpg',
        title: 'Kitty Azoth',
        type: 'Fanart',
        description: 'Requested by: @eXoofed'
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/030/133/151/small/carol-galvao-carol_juskit-hellboy.jpg',
        title: 'Hellboy',
        type: 'Commissioned Fanart',
        description: ''
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/030/195/280/small/carol-galvao-carol_juskit-ghost.jpg?',
        title: 'Smoothie monster',
        type: 'Commissioned Fanart',
        description: 'For: @ItsJustSlumpz'
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/030/297/674/small/carol-galvao-carol_juskit-butterscotch.jpg?',
        title: 'Butterscotch mage',
        type: 'Fanart',
        description: ''
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/031/107/620/small/carol-galvao-carol_juskit-grugach-original-watermark2.jpg?',
        title: 'Gruagach on vacation',
        type: 'Commissioned Fanart',
        description: ''
      },
    ],
  },
  {
    title: 'Ink And Paper',
    images: [
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/030/190/882/small/carol-galvao-carol_juskit-egzs1gbxuaass5.jpg',
        title: 'Basic shape boy',
        type: 'Original Creation',
        description: ' '
      },
      {
        src: 'https://cdnb.artstation.com/p/assets/images/images/030/190/945/small/carol-galvao-carol_juskit-d12.jpg?',
        title: 'Doll',
        type: 'Original Creation',
        description: ' '
      },
      {
        src: 'https://cdna.artstation.com/p/assets/images/images/030/190/970/small/carol-galvao-carol_juskit-d7.jpg?',
        title: 'Kagetane Hiruko',
        type: 'Original Creation',
        description: ' '
      },
    ],
  },
]
