import { mdiArtstation, mdiDeviantart, mdiTwitter, mdiPatreon } from '@mdi/js'

export default [
  {
    icon: mdiArtstation,
    to: 'https://www.artstation.com/carol_juskit'
  },
  {
    icon: mdiDeviantart,
    to: 'https://www.deviantart.com/juskit'
  },
  {
    icon: mdiTwitter,
    to: 'https://twitter.com/Carol_juskit'
  },
  {
    icon: mdiPatreon,
    to: 'https://www.patreon.com/carol_juskit'
  },
]