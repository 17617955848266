<template>
  <v-row
    class="pa-0 ma-0"
  >
    <v-col 
      class="pa-0 ma-0"
    >

      <!-- DIGITAL -->
      <v-row
        :key="index"
        v-for="(galery, index) in galeries"
        style="width: 100vw"
        class="px-0 ma-0"
        justify="center"
      >
        <v-card
          flat
          :dark="!!(index%2)"
        >
          <v-img
            width="100vw"
            :gradient="!(index%2) ? 'to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, .3)' : 'to top, rgba(0, 0, 0, .99), rgba(0, 0, 0, 1)'"
          >
            <v-card-title
              class="display-2 mt-3"
            >
              {{ galery.title }}
            </v-card-title>
            <CarouselModule :list="galery.images"/>
          </v-img>
        </v-card>
      </v-row>

      <v-dialog 
        v-model="Dialog" 
        fullscreen
        scrollable
        transition="dialog-bottom-transition"
        @keydown.esc.stop="setDialog(false)"
      >
        <v-card
          color="rgba(0,0,0, .90)"
        >
          <v-zoomer
            :limitTranslation="true"
            style="width: 100%; height: 100%;"
          >
            <v-img
              :src="DialogImage"
              height="100%"
              width="100%"
              contain
            />
            <!-- <img
              :src="DialogImage"
              style="object-fit: contain; width: 100%; height: 100%;"
            > -->
          </v-zoomer>
          <v-btn 
            dark 
            icon 
            fixed 
            large
            top 
            left 
            @click="setDialog(false)"
          >
            <v-icon>{{icon.mdiClose}}</v-icon>
          </v-btn>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import CarouselModule from './post/carouselComponent'
import { mapActions, mapGetters } from 'vuex'
import galeries from '../../easyEdit/galeries'
import {mdiClose} from '@mdi/js'


export default {
  name: 'Portfolio',
  data: () => ({
    galeries: galeries,
    icon: {
      mdiClose
    }
  }),
  components: {
    CarouselModule,
  },
  computed: {
    ...mapGetters('portfolioModule', ['DialogImage', 'Dialog']),
  },
  methods: {
    ...mapActions('portfolioModule', ['setDialog']),
  },
}
</script>