import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'

// COMENTARIO MAIS RAPIDO DO OESTE
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueZoomer from 'vue-zoomer'

Vue.use(VueZoomer)
Vue.use(VueAwesomeSwiper, /* stufff here */)

Vue.config.productionTip = false

// COMENTEI
// Comentario Obscuro
new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
