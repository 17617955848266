import API from '../../axios-api'
import aes256 from 'aes256'

function encryptData (data) {
  return ({
    encrypted: aes256.encrypt(process.env.VUE_APP_SECRET_KEY_JWT, JSON.stringify(data))
  })
}
export default {
  // eslint-disable-next-line no-empty-pattern
  sendEmail: async ({}, payload) => {
    const config = {
      headers: {
        Authorization: process.env.VUE_APP_EMAIL_TOKEN,
        'Content-Type': 'application/json'
      }
    }

    const message = `Contato recebido pelo site.\nFormulário: \n\n${JSON.stringify(payload,'', 2)}`.replace(/["]/g, '')

    const data = {
      user: process.env.VUE_APP_EMAIL_USER, 
      password: process.env.VUE_APP_EMAIL_PASSWORD,
      from: process.env.VUE_APP_EMAIL_FROM, 
      to: process.env.VUE_APP_DESTINATION,
      subject: 'CONTATO RECEBIDO', 
      message: message,
    }

    const data2 = {
      user: process.env.VUE_APP_EMAIL_USER, 
      password: process.env.VUE_APP_EMAIL_PASSWORD,
      from: process.env.VUE_APP_EMAIL_FROM, 
      to: payload.email,
      subject: 'Carolina S. Galvão - Thank You For Your Contact', 
      message: `Hi ${payload.name}\n\nThanks for your contact, I will contact you back as soon as possible.\n\nBest regards\n\nCarolina S. Galvão\n\n\n\nThis is an automatic message.`,
    }
    
    return API.post('/mail', encryptData(data), config)
      .then(() => {
        API.post('/mail', encryptData(data2), config)
        return ({
          state: true, 
          message: 'Message sent successfully!',
          color: 'success'
        })
      })
      .catch((err) => {
        console.error(err)
        return ({
          state: true, 
          message: 'There was an error sending your message. Please try again.',
          color: 'error'
        })
      })
  },
}