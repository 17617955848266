<template>
  <v-img
    src="https://cdnb.artstation.com/p/assets/images/images/024/317/929/medium/carol-galvao-juskit-lua-atualizado.jpg"
    lazy-src="../assets/images/header/carol-galvao-juskit-lua-atualizado.webp"
    height="100vh"
    width="100%"
    gradient="to bottom, rgba(200, 200, 200, .1), rgba(0, 0, 0, .99)"
  >
    <v-row
      style="height: 100vh"
      align="center"
    >
        <v-col
          cols="12"
          align="center"
        >
          <v-avatar
            width="170px"
            height="170px"
            class="mb-4"
          >
            <v-img 
              src="../assets/images/header/profile.webp"
            />
          </v-avatar><br>
          <v-img
            alt="Carol Logo"
            class="shrink mr-8 mt-1"
            contain
            src="../assets/images/header/signature-white.webp"
            transition="scale-transition"
            width="350px"
          />
          <v-row
            justify="center"
          >
            <v-btn :key="index" v-for="(item, index) in icons"
              class="mx-5 mt-2"
              fab
              dark
              icon
              :href="item.to"
              target="_blank"
            >
              <v-icon> {{ item.icon }} </v-icon>
            </v-btn>
          </v-row>
          <v-btn
            dark
            absolute
            bottom
            center
            icon
            large
            style="
              bottom: 10%
            "
          >
            <v-icon
              @click="$vuetify.goTo(windowHeight)"
            >
              {{icon.mdiArrowDown}}
            </v-icon>
          </v-btn>
        </v-col>
    </v-row>
  </v-img>
</template>

<script>
  import refIcons from '../../easyEdit/refIcons'
  import {mdiArrowDown} from '@mdi/js'

  const iconlist = []
  refIcons.map((item) => iconlist.push(item.icon))
  console.log(iconlist.join(', '))

  export default {
    name: 'Header',

    data: () => ({
      icons: refIcons,
      icon: {
        mdiArrowDown
      }
    }),
    computed: {
      windowHeight: () => window.innerHeight-25,
    }
  }
</script>
