<template>
  <v-card
    width="400px"
    height="550px"
    class="ma-1"
    @click.stop="zoomIn(src)"
    tile
  >
    <v-img
      :src="src"
      width="400px"
      height="550px"
      gradient="to top, rgba(0, 0, 0, .9), rgba(0, 0, 0, .0)"
    >
      <v-row
        class="pa-3 ma-0"
        style="height: 100%"
        align="end"
      >
        <v-col>
          <v-row>
            <v-col>
              <span
                class="display-1 white--text"
              >
                {{ title }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                class="subtitle-1 white--text"
              >
                {{ type }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                class="caption white--text"
              >
                {{ description }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'post',
  props: {
    src: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('portfolioModule', ['zoomIn'])
  }
}
</script>